/* styles.css */

:root {
  --main-bg-color: #000000;
  --main-bg-color-toggle: #2fbd0fc6;
  --text-color: #49de80;
  --border-color: #49de80;
  --hover-color: #2ebd0f;
  --hover-color-toggle: #49de80;
  --hover-color-links: #49de80;
}

:root[data-theme="light"] {
  --main-bg-color: #ffffff;
  --main-bg-color-toggle: #ffffff;
  --text-color: #000000;
  --border-color: #000000;
  --hover-color: #dddddd;
  --hover-color-toggle: #dddddd;
  --hover-color-links: rgb(46, 36, 36);
}

/* Theme Toggle Button */
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--toggle-bg-color, var(--main-bg-color-toggle));
  border: 2px solid var(--toggle-border-color, var(--border-color));
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s;
}

.theme-toggle:hover {
  background-color: var(--toggle-hover-bg-color, var(--hover-color-toggle));
  border-color: var(--toggle-hover-border-color, var(--border-color));
}

body {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--main-bg-color);
  margin: 0;
  padding: 0;
}

.p-color {
  color: var(--text-color);
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
}

.h1 {
  text-align: left;
}
/* Header */
.header {
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0;
  text-align: left;
  align-items: center;
}

/* Header */
.header-toggle {
  /* border-bottom: 1px solid var(--border-color); */
  /* padding: 1rem 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.main-title span {
  margin-left: 0.5rem;
}

.subtitle {
  font-size: 2rem;
}

/* Main content */
.main-content {
  padding: 2rem 0;
}

.section {
  margin-bottom: 2rem;
  text-align: left;
  align-items: center;
}

.add-padding {
  padding-bottom: 0.5rem;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.section-title span {
  margin-left: 0.5rem;
}

/* Services */
.services-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordion-item {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.accordion-button {
  font-size: 1rem;
  width: 100%;
  text-align: left;
  padding: 1rem;
  background-color: transparent;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button:hover {
  background-color: rgba(57, 255, 20, 0.1);
}

.accordion-content {
  padding: 1rem;
  border-top: 1px solid var(--border-color);
}

/* Footer */
.footer {
  border-top: 1px solid var(--border-color);
  padding: 1rem 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* Icons */
.icon-title {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-right: 2px;
  vertical-align: middle;
}
.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: align-middle;
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.no-bullets li {
  display: flex;
  align-items: center;
}

.no-bullets li svg {
  margin-right: 8px;
}

.carousel-container {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  font-family: monospace;
  padding: 20px;
  text-align: left;
  font-size: 1rem;
  /* border: 1px solid var(--border-color); */
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem; /* Match margin-bottom of accordion-item */
}

.carousel-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-track {
  display: flex;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-card {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--card-bg-color);
  color: var(--text-color);
}

.carousel-card-title {
  /* background-color: var(--card-hover-bg-color); */
  background-color: rgba(57, 255, 20, 0.1);
  margin: 0;
  padding: 1rem;
}

.carousel-card-title a {
  color: var(--text-color);
  text-decoration: none;
}

.carousel-card-content {
  padding: 1rem;
}

.carousel-card-date {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.carousel-card-link {
  color: var(--hover-color-links);
  text-decoration: underline;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--hover-color);
  border: 2px solid var(--border-color);
  color: var(--text-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s, border-color 0.3s;
}

.carousel-control:hover {
  background-color: var(--main-bg-color-toggle);
  border-color: var(--hover-color-toggle);
}

/* .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 10px;
  } */

/* second */
.carousel-control.prev {
  right: 10px;
  margin-bottom: 50px;
}

.carousel-control.next {
  right: 10px;
  margin-top: 50px;
}

/* Circular Image */
/* .team-member img {
  border-radius: 50%;
  width: 100px; 
  height: 100px; 
  object-fit: cover;
} */

.team-member {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between image and text as necessary */
  margin-bottom: 2rem; /* Adjust the space between team members */
}

.team-member img {
  border-radius: 50%;
  width: 150px; /* Adjust as necessary */
  height: 150px; /* Adjust as necessary */
  object-fit: cover;
  border: 5px solid var(--border-color); /* Adds a border */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a shadow */
}

.team-member-info {
  display: flex;
  flex-direction: column;
}

.team-member-info h3 {
  margin-top: 0; /* Remove margin from the top of the name */
  font-size: 1.5rem; /* Adjust font size as necessary */
}

.team-member-info p {
  margin: 5px 0; /* Adjust the space between role and description */
  font-size: 1rem; /* Adjust font size as necessary */
}

.blog-carousel {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem 0;
  margin-bottom: 1rem; /* Add margin to show full bottom border */
}

.blog-carousel::-webkit-scrollbar {
  display: none;
}

.blog-carousel-inner {
  display: flex;
  gap: 1rem;
  padding-bottom: 1px; /* Add padding to show full bottom border */
}

.blog-carousel-item {
  flex: 0 0 auto;
  width: 300px;
  height: 320px; /* Keep the height as is */
}

.blog-card {
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  height: 100%;
  box-sizing: border-box; /* Ensure padding is included in height */
}

.blog-card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  /* Remove line clamping to show full title */
  overflow: visible;
  word-wrap: break-word;
  height: 3.3em; /* Approximately 3 lines of text */
  line-height: 1.1em;
}

.blog-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.blog-card-date {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.7;
  margin-bottom: 0.5rem;
  height: 1em; /* Fixed height for alignment */
  line-height: 1em;
}

.blog-card-description {
  color: var(--text-color);
  margin-bottom: 0.5rem;
  flex-grow: 1;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Show 4 lines of description */
  -webkit-box-orient: vertical;
}

.blog-card-link {
  margin-top: auto;
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
  padding-top: 0.5rem;
}

.blog-carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: var(--text-color);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.blog-carousel-control.left {
  left: -1rem;
}

.blog-carousel-control.right {
  right: -1rem;
}

@media (max-width: 640px) {
  .blog-carousel-control {
    display: none;
  }
}
